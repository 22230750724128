import React, { FC } from "react";
import "./App.css";

export const Footer: FC = () => {
  return (
    <>
      <div
        style={{ fontFamily: "Nunito Sans" }}
        className="marquee-container flex items-center h-[68px] bg-[#2131BA]"
      >
        <div className="marquee-content flex items-center gap-[30px]">
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex items-center gap-[16px] text-[#fff] text-[18px] not-italic font-[800] leading-[normal]">
            <p>COMING SOON</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 13H14.4L19.1 17.7L17.7 19.1L13 14.4V21H11V14.3L6.3 19L4.9 17.6L9.4 13H3V11H9.6L4.9 6.3L6.3 4.9L11 9.6V3H13V9.4L17.6 4.8L19 6.3L14.3 11H21V13Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
